.input-bill-number{
    background: white;
    border-left: 5px solid #01333e;
    margin-bottom: 20px;
    font-size: 14px;
    padding: 15px;
    /* box-shadow: 0px 8px 9px -5px rgb(0 0 0 / 20%), 0px 15px 22px 2px rgb(0 0 0 / 14%), 0px 6px 28px 5px rgb(0 0 0 / 12%); */
}
.billing-data-grid-container{
    display: grid;
    justify-content: center;
    align-items: center;
    align-content: center;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px ;
    margin-bottom: 10px;
}



@media (max-width: 768px) {
    .billing-data-grid-container{
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 10px ;
    }
}


/* Mobile styles */
@media (max-width: 767px) {
    .billing-button-grid-container {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 5px;
        row-gap: 10px;
        justify-content: center;
        margin-top: 10px;
        justify-items: center;
    }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1023px) {
    .billing-button-grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 10px;
        row-gap: 10px;
        justify-content: center;
        margin-top: 10px;
        justify-items: center;
    }
}

/* Desktop styles */
@media (min-width: 1024px) {
    .billing-button-grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 50px;
        row-gap: 10px;
        justify-content: center;
        margin-top: 10px;
        justify-items: center;
    }
}

.billing-data-container{
    border-left: 5px solid #01333e;
    background: white;
    height: 145px;
    width: "auto";
    padding: 15px;
    justify-content: center;
    align-content: center;
}

.billing-cust-button-data{
    display: flex; 
    justify-content: flex-end; 
    margin: 20px 0 15px 0;
}

@media (max-width: 768px) {
    .billing-cust-button-data {
        display: flex; 
        justify-content: flex-start; 
        margin: 20px 5px 15px 0;
    }
}

.billing-cust-input-data{
    width: 400px;
}

@media (max-width: 768px) {
    .billing-cust-input-data {
        width: 250px;
    }
}

.billing-data-container .existing-customer-search, 
.billing-data-container .existing-customer__error, 
.billing-data-container .shop-details,
.billing-data-container .customer-details{
    font-size: 15px;
}


.bill-header {
    font-size: 16px;
    font-weight: 600;
    color: #2f1160; 
}

.customer-name, .shop-name {
    font-size: 20px;
    font-weight: 600;
    color: #2f1160;    
}

.table-no {
    font-size: 15px;
    color: #2f1160; 
}

.resto-price {
    font-size: 15px;
    color: #601d11; 
}

.shop-address, .shop-gst, .customer-mob-num, .customer-email-id{
    color: #2f1160;
}


.billing-data-container:hover{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}


.summary{
    margin-top : 20px;
    min-height: 100px;
    background: white;
    border-radius: 16px;
    box-shadow: 0px 8px 9px -5px rgb(0 0 0 / 20%), 0px 15px 22px 2px rgb(0 0 0 / 14%), 0px 6px 28px 5px rgb(0 0 0 / 12%);
}

.royalty-redeem-container .royalty-redeem{
    padding: 15px 20px;
    color: #00333E;
}

.summary-section{
    padding: 10px;
}

.summary-section__left{
    display: flex;
    align-items: center;
}

.summary-section.summary-section__right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #00333E;
}

.summary-section.summary-section__right .summary-section__total{
    font-size: 40px;
    color: #2f1160; 
}

.summary-section__radio {
    width: 30%;
}


@media (max-width: 767px) {
    .table-data-container{
        border-left: 5px solid #01333e;
        border-radius: 5px;
        background: white;
        height: 138px;
        width: 135px;
        padding: 1px;
        justify-content: center;
        text-align: center;
    }
}

.table-data-container{
    border-left: 5px solid #01333e;
    border-radius: 5px;
    background: white;
    height: 145px;
    width: 142px;
    padding: 1px;
    justify-content: center;
    text-align: center;
}

.showbills-data-container{
    border-left: 5px solid #01333e;
    border-radius: 5px;
    background: white;
    height: 145px;
    width: 145px;
    padding: 1px;
    justify-content: center;
    text-align: center;
}

.button-bill-data-container{
    border-left: 5px solid #01333e;
    border-radius: 5px;
    background: white;
    height: 100px;
    width: 205px;
    padding: 8px;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: double;
    font-size: larger;
}

.restaurant-data-container{
    border-left: 5px solid #01333e;
    border-radius: 5px;
    background: white;
    height: 100px;
    width: 100px;
    padding: 2px;
}

.restaurant-product-data-container {
    
    border-radius: 5px;
    background: white;
    height: 120px;
    width: 120px;
    padding: 1px;
    justify-content: center;
    align-content: center;
}


.balance-data-grid-container{
    display: grid;
    grid-template-columns: repeat(2, 0.5fr);
    justify-content: center;
    align-content: center;
}

.restaurant-todaybal-data-container {
    
    border-radius: 2px;
    background: white;
    height: 75px;
    width: 75px;
    padding: 1px;
    justify-content: center;
    align-content: center;
}

.restaurant-right-data-container{
    border-left: 5px solid #01333e;
    border-radius: 5px;
    background: white;
    height: 60px;
    width: 95px;
    padding: 2px;
}

.table-data-container:hover{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.showbills-data-container:hover {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.button-bill-data-container:hover{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.restaurant-data-container:hover{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
.restaurant-right-data-container:hover{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.restaurant-product-data-container:hover{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
.restaurant-todaybal-data-container:hover {
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.table-booked-container{
    border-left: 5px solid #01333e;
    border-radius: 5px;
    background: rgb(189, 116, 67);
    background-color:#00333E;
    height: 100px;
    width: 100px;
    padding: 15px;
}


.table-booked-container:hover{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.table-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50vh;
    align-items: center;
    justify-content: center;
  }
  .sample-check{
    background-color:#FFFFFF;
  }


  @media print {
    /* Define print styles for Printer 1 */
    @page Printer1 {
      size: 8.5in 11in;
    }
  
    /* Define print styles for Printer 2 */
    @page Printer2 {
      size: 11in 17in;
    }
  }