*{
    box-sizing: border-box;
}
div{
    font-size: 17px;
    
    
}
.container{
    
    padding: 5px 20px 15px 20px;
    border: 1px solid lightgray;
    border-radius: 4px;
}
